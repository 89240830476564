.p-40 {
  padding: 40px;
}

.radius-10 {
  border-radius: 10px;
}

.profile-pg-mb-30 {
  margin-bottom: 30px;
}

.myItem {
  cursor: default;
  color: var(--color-black);
  font-size: 20px;
  display: flex;
}

.filter-option-inner-contnet .profile-pg-tabs-name div:first-child {
  padding-top: 0;
}

.filter-option-inner-contnet .profile-pg-tabs-name li {
  padding: 12.5px 0;
  font-size: 17px;
  line-height: 1.11;
  color: #7c8188;
  cursor: pointer;
  position: relative;
  font-weight: normal;
}

.filter-option-inner-contnet .profile-pg-tabs-name li.active::after {
  content: "";
  height: 75%;
  width: 3px;
  background: var(--color-RegularActiveText);
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
}

.filter-option-inner-contnet
  .profile-pg-tabs-name
  .fil-ops-activated
  .fil-ops:hover {
  border-radius: 20px;
  padding: 7px 25px;
  /* transition: 0.5s ease; */
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #3fc1cb !important;
  color: var(--button-text-primary) !important;
  cursor: pointer;
}

.filter-option-inner-contnet .profile-pg-tabs-name li:last-child {
  border: none;
  padding-bottom: 0;
}

.filter-option-inner-contnet
  .profile-pg-tabs-name
  .fil-ops-activated
  .fil-ops.active {
  background: #3fc1cb;
  border-radius: 20px;
  font-weight: 500;
  color: #fff;
  padding: 7px 25px;
  cursor: pointer;
}

.list-style-none {
  list-style: none;
}

.category-name {
  text-transform: capitalize;
}

.more-btn-none {
  display: none;
}

.more-btn-inline {
  height: 325px;
  overflow-y: scroll;
  display: block;
  position: absolute;
  z-index: 9;
  background: #ffffff;
  border: 1px solid #dfe0e6;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px #ebebeb;
  border-radius: 10px;
  top: 40px;
  left: 50%;
  transform: translate(-80%, 0);
}

.more-btn-inline div.active {
  background-color: #3fc1cb;
  color: #fff;
}

.border-bottom {
  border: 1px solid #dfdfdf;
}

.more-btn-inline::-webkit-scrollbar {
  width: 5px;
}

.more-btn-inline::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.more-btn-inline::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.more-btn-inline::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fil-ops {
  padding: 0px 25px;
  font-size: 18px;
  font-weight: 400;
}

.fil-ops:active {
  padding: 0px 25px;
  font-size: 18px;
  font-weight: 500;
}

.more-fill-pills:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #3fc1cb;
  padding: 7px 25px;
  border-radius: 20px;
}

.more-fill-pills:active {
  background: #3fc1cb;
  border-radius: 20px;
  font-weight: 500;
  color: #fff;
  padding: 7px 25px;
  cursor: pointer;
}

.more-fill-pills {
  padding: 7px 25px;
  cursor: pointer;
}

.pills-button {
  height: auto;
  display: flex;
}

.category-pills-btn {
  background: transparent !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #000 !important;
  border: none !important;
  min-width: max-content;
  border-radius: 20px !important;
  padding: 9px 24px 9px 24px !important;
  margin-right: 2.5rem;
}

.category-pills-btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.category-pills-btn.active {
  background: #3fc1cb !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.category-pills-btn:hover {
  color: #000 !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    #26d8e4 !important;
}

.pills-button::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .pills-button {
    width: 100% !important;
  }
  .category-pills-btn.active {
    background: #3fc1cb !important;
    font-weight: 500 !important;
    color: #fff !important;
    font-size: 14px !important;
  }
}

@media (max-width: 1440px) {
  .more-btn-inline {
    display: block;
    position: absolute;
    z-index: 9;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    right: 100px;
    right: auto;
    left: auto;
    transform: translate(-80%, 0);
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .profile-pg-inner-wrap {
    /* Column One */
    padding: 40px 30px 35px !important;
  }

  .profile-pg-inner-wrap.profile-inner-tab-wrap {
    /*Column One */
    padding: 20px;
  }

  .profile-pg-inner-wrap.profile-inner-tab-content {
    /*Column One */
    padding: 30px;
  }

  .filter-option-inner-contnet .profile-pg-tabs-name li.active::after {
    /*Column One */
    right: -30px !important;
  }

  .filter-option-inner-contnet .profile-pg-tabs-name li {
    /*Column One */
    font-weight: normal;
  }
}

@media (max-width: 1279.92px) {
  .profile-pg-inner-wrap,
  .tab-content .profile-pg-inner-wrap {
    /*Column One */
    padding: 15px !important;
  }

  .profile-pg-inner-wrap.profile-inner-tab-wrap {
    /*Column One */
    margin-bottom: 10px;
  }

  .profile-inner-tab-content {
    /*Column One */
    padding: 18px 15px 15px !important;
  }

  .filter-option-inner-contnet .profile-pg-tabs-name li.active::after {
    /*Column One */
    right: -15px;
  }

  .more-btn-inline {
    display: block;
    position: absolute;
    z-index: 9;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    right: auto;
    transform: translate(-80%, 0);
  }
}

@media (max-width: 300.92px) {
  .more-btn-inline {
    display: block;
    position: absolute;
    z-index: 9;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    right: auto;
    transform: translate(-80%, 0);
  }
}

@media (max-width: 767.92px) {
  .profile-pg-inner-wrap,
  .tab-content .profile-pg-inner-wrap {
    /*Column One */
    padding: 20px !important;
  }

  .sticky-inner-wrapper {
    position: inherit !important;
  }

  .fil-ops {
    padding: 0px 10px;
    font-size: 14px;
    font-weight: normal;
  }

  .more-btn-inline {
    display: block;
    position: absolute;
    z-index: 9;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    cursor: pointer;
    right: auto;
    transform: translate(-95%, 0);
  }
}
